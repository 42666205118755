<template>
  <div>
    <div class="container">
      <div class="sctp-bg-white sctp-pad15 sctp-mar-b10 sctp-flex subMenu-wrap">
        <template v-for="menu in menus">
          <div :key="menu.name" :id="menu.name">
            <router-link :to="menu.url">{{ menu.name }}</router-link>
          </div>
        </template>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'favorite',
  data() {
    return {
      menus: [
        {name: '店铺收藏', url: '/user/favorite/shop'},
        {name: '需求收藏', url: '/user/favorite/require'},
        {name: '源码收藏', url: '/user/favorite/product'},
        {name: '兼职收藏', url: '/user/favorite/part-time'},
        {name: '设计收藏', url: '/user/favorite/design'},
        {name: '服务收藏', url: '/user/favorite/service'},
        {name: '文档收藏', url: '/user/favorite/doc'},
      ]
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
/*@formatter:off*/
  .container .subMenu-wrap {height: 49px;font-size: 15px;font-weight: 700;}
  .container .subMenu-wrap > div {border-right: 1px solid #e5e5e5;padding: 0 30px;}
  .container .router-link-active {color: #FF9900!important;}
  /*@formatter:on*/
</style>
